module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing-3/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"defaultStyles":{"overlay":{"position":"fixed","top":0,"left":0,"right":0,"bottom":0,"backdropFilter":"blur(20px)","zIndex":9888},"content":{"position":"absolute","top":0,"left":0,"right":0,"bottom":0,"outline":0,"width":"100%","height":"100vh","overflow":"hidden"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MrMr","short_name":"mrmr","start_url":"/","background_color":"#000000","theme_color":"#fc0fc0","display":"minimal-ui","icon":"src/images/apple-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b59a528cfe5c03b5a938a8bdface53d7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/web/src/components/layout.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
