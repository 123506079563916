import React from "react"

const defaultState = {
  isOpen: false,
  filterIsActive: false,
  heroVideoIsPlaying: false,
  toggleIsOpen: () => {},
  closeMenu: () => {},
  toggleFilter: () => {},
  togglePauseHeroVideo: () => {},
}

const StoreContext = React.createContext(defaultState)

class StoreProvider extends React.Component {
  state = {
    isOpen: false,
    filterIsActive: false,
    heroVideoIsPlaying: true
  }

  toggleIsOpen = () => {
    let isOpen = !this.state.isOpen
    let heroVideoIsPlaying = !this.state.heroVideoIsPlaying
    this.setState({ isOpen, heroVideoIsPlaying })
  }

  toggleFilter = () => {
    let filterIsActive = !this.state.filterIsActive
    this.setState({ filterIsActive })
  }

  closeMenu = () => {
    this.setState({ isOpen: false })
  }

  playHeroVideo = () => {
    this.setState({ heroVideoIsPlaying: true })
  }

  pauseHeroVideo = () => {
    this.setState({ heroVideoIsPlaying: false })
  }

  render() {
    const { children } = this.props
    const { isOpen, filterIsActive, heroVideoIsPlaying } = this.state
    return (
      <StoreContext.Provider
        value={{
          isOpen,
          filterIsActive,
          heroVideoIsPlaying,
          toggleIsOpen: this.toggleIsOpen,
          toggleFilter: this.toggleFilter,
          closeMenu: this.closeMenu,
          playHeroVideo: this.playHeroVideo,
          pauseHeroVideo: this.pauseHeroVideo,
        }}
      >
        {children}
      </StoreContext.Provider>
    )
  }
}

export default StoreContext

export { StoreProvider }