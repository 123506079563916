import { Link } from "gatsby";
import React, { useContext } from "react";
import { cn } from "../lib/helpers";
import StoreContext from "../context/StoreContext";
import BlockText from "./block-text";
import Logo from "../../static/logo.inline.svg";

import * as styles from "./header.module.css";

const Header = ({ siteTitle, bio }) => {
  const store = useContext(StoreContext);

  return (
    <div className={cn(styles.root, store.isOpen ? styles.menuIsOpen : '')}>
      <div className={styles.branding}>
        <Link to="/">
          <Logo className={cn(styles.logo, store.isOpen ? styles.menuIsOpen : '', store.filterIsActive ? styles.filterIsActive : '')} />
        </Link>
        <nav className={cn(styles.smallNavList, store.isOpen ? styles.menuIsOpen : '')}>
          <ul>
            <li>
              <Link to="/music" activeClassName={styles.isActive}><span>Music</span></Link>
            </li>
            <li>
              <Link to="/commercial" activeClassName={styles.isActive}><span>Commercial</span></Link>
            </li>
            <li>
              <Link to="/film" activeClassName={styles.isActive}><span>Film</span></Link>
            </li>
          </ul>
        </nav>
        <h1>{siteTitle}</h1>
      </div>

      <div className={cn(styles.nav, store.isOpen ? styles.menuIsOpen : '')}>
        <div className={cn(styles.bio, store.isOpen ? styles.menuIsOpen : '')}>
          <BlockText blocks={bio} />
        </div>
        <nav className={cn(styles.navList, store.isOpen ? styles.menuIsOpen : '')}>
          <ul>
            <li>
              <Link to="/music" activeClassName={styles.isActive}><span>Music</span></Link>
            </li>
            <li>
              <Link to="/commercial" activeClassName={styles.isActive}><span>Commercial</span></Link>
            </li>
            <li>
              <Link to="/film" activeClassName={styles.isActive}><span>Film</span></Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )};

export default Header;
