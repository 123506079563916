import { Link } from "gatsby";
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

import * as styles from "./figure.module.css";

export function Figure({ node, slug }) {
  if (!node.asset) {
    return null;
  }

  const imageData = getGatsbyImageData(node.asset, { maxWidth: 675 }, clientConfig.sanity);

  return (
    <Link 
      to={`/project/${slug}`}
      state={{
        modal: true,
        noScroll: true,
        drawerIsOpen: true
      }}
    >
      <figure className={styles.root}>
        <GatsbyImage image={imageData} alt={node.alt ? node.alt : ''} />
      </figure>
    </Link>
  );
}
