import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { cn } from "../lib/helpers";
import "@fontsource/archivo/500.css";
import "@fontsource/archivo/700.css";
import "@fontsource/archivo/400-italic.css";
import StoreContext, { StoreProvider } from "../context/StoreContext";
import Header from "./header";

import "../styles/layout.css";
import * as styles from "./layout.module.css";

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={infoQuery}
      render={data => {
        const info = (data || {}).info;
        const { _rawBio } = info;
        return (
          <StoreProvider>
            <StoreContext.Consumer>
              {store => (
                <div>
                  <Header siteTitle={'MrMr'} bio={_rawBio} />
                  <div className={styles.content}>{children}</div>
                  <div className={cn(styles.backdrop, store.isOpen ? styles.isActive : '')}></div>
                </div>
              )}
            </StoreContext.Consumer>
          </StoreProvider>
        );
      }}
    />
  );
};

export default Layout;

const infoQuery = graphql`
  query InfoQuery {
    info: sanityInformation {
      _rawBio
    }
  }
`;