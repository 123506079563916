// extracted by mini-css-extract-plugin
export const root = "header-module--root--2Pllp";
export const menuIsOpen = "header-module--menuIsOpen--2kVnb";
export const branding = "header-module--branding--1PjA2";
export const logo = "header-module--logo--3gIix";
export const filterIsActive = "header-module--filterIsActive--3aYGZ";
export const nav = "header-module--nav--106zH";
export const bio = "header-module--bio--T3gGM";
export const smallNavList = "header-module--smallNavList--5Xbha";
export const isActive = "header-module--isActive--3EQlP";
export const navList = "header-module--navList--3Sbqc";